html, body, #root {
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html, .smoothScroll {
  scroll-behavior: smooth;
}
